import { defineMessages } from 'react-intl'

/**
 * Internationalized messages for country names by country code.
 */
export default defineMessages({
  ALL: {id: "CountryName.ALL", defaultMessage: "All Countries"},
  AF: {id: "CountryName.AF", defaultMessage: "Afghanistan"},
  AO: {id: "CountryName.AO", defaultMessage: "Angola"},
  AL: {id: "CountryName.AL", defaultMessage: "Albania"},
  AE: {id: "CountryName.AE", defaultMessage: "United Arab Emirates"},
  AR: {id: "CountryName.AR", defaultMessage: "Argentina"},
  AM: {id: "CountryName.AM", defaultMessage: "Armenia"},
  AQ: {id: "CountryName.AQ", defaultMessage: "Antarctica"},
  TF: {id: "CountryName.TF", defaultMessage: "French Southern Territories"},
  AU: {id: "CountryName.AU", defaultMessage: "Australia"},
  AT: {id: "CountryName.AT", defaultMessage: "Austria"},
  AZ: {id: "CountryName.AZ", defaultMessage: "Azerbaijan"},
  BI: {id: "CountryName.BI", defaultMessage: "Burundi"},
  BE: {id: "CountryName.BE", defaultMessage: "Belgium"},
  BJ: {id: "CountryName.BJ", defaultMessage: "Benin"},
  BF: {id: "CountryName.BF", defaultMessage: "Burkina Faso"},
  BD: {id: "CountryName.BD", defaultMessage: "Bangladesh"},
  BG: {id: "CountryName.BG", defaultMessage: "Bulgaria"},
  BS: {id: "CountryName.BS", defaultMessage: "Bahamas"},
  BA: {id: "CountryName.BA", defaultMessage: "Bosnia and Herzegovina"},
  BY: {id: "CountryName.BY", defaultMessage: "Belarus"},
  BZ: {id: "CountryName.BZ", defaultMessage: "Belize"},
  BO: {id: "CountryName.BO", defaultMessage: "Bolivia"},
  BR: {id: "CountryName.BR", defaultMessage: "Brazil"},
  BN: {id: "CountryName.BN", defaultMessage: "Brunei"},
  BT: {id: "CountryName.BT", defaultMessage: "Bhutan"},
  BW: {id: "CountryName.BW", defaultMessage: "Botswana"},
  CF: {id: "CountryName.CF", defaultMessage: "Central African Republic"},
  CA: {id: "CountryName.CA", defaultMessage: "Canada"},
  CH: {id: "CountryName.CH", defaultMessage: "Switzerland"},
  CL: {id: "CountryName.CL", defaultMessage: "Chile"},
  CN: {id: "CountryName.CN", defaultMessage: "China"},
  CI: {id: "CountryName.CI", defaultMessage: "Ivory Coast"},
  CM: {id: "CountryName.CM", defaultMessage: "Cameroon"},
  CD: {id: "CountryName.CD", defaultMessage: "Congo (Kinshasa)"},
  CG: {id: "CountryName.CG", defaultMessage: "Congo (Brazzaville)"},
  CO: {id: "CountryName.CO", defaultMessage: "Colombia"},
  CR: {id: "CountryName.CR", defaultMessage: "Costa Rica"},
  CU: {id: "CountryName.CU", defaultMessage: "Cuba"},
  CY: {id: "CountryName.CY", defaultMessage: "Cyprus"},
  CZ: {id: "CountryName.CZ", defaultMessage: "Czech Republic"},
  DE: {id: "CountryName.DE", defaultMessage: "Germany"},
  DJ: {id: "CountryName.DJ", defaultMessage: "Djibouti"},
  DK: {id: "CountryName.DK", defaultMessage: "Denmark"},
  DO: {id: "CountryName.DO", defaultMessage: "Dominican Republic"},
  DZ: {id: "CountryName.DZ", defaultMessage: "Algeria"},
  EC: {id: "CountryName.EC", defaultMessage: "Ecuador"},
  EG: {id: "CountryName.EG", defaultMessage: "Egypt"},
  ER: {id: "CountryName.ER", defaultMessage: "Eritrea"},
  ES: {id: "CountryName.ES", defaultMessage: "Spain"},
  EE: {id: "CountryName.EE", defaultMessage: "Estonia"},
  ET: {id: "CountryName.ET", defaultMessage: "Ethiopia"},
  FI: {id: "CountryName.FI", defaultMessage: "Finland"},
  FJ: {id: "CountryName.FJ", defaultMessage: "Fiji"},
  FK: {id: "CountryName.FK", defaultMessage: "Falkland Islands"},
  FR: {id: "CountryName.FR", defaultMessage: "France"},
  GA: {id: "CountryName.GA", defaultMessage: "Gabon"},
  GB: {id: "CountryName.GB", defaultMessage: "United Kingdom"},
  GE: {id: "CountryName.GE", defaultMessage: "Georgia"},
  GH: {id: "CountryName.GH", defaultMessage: "Ghana"},
  GN: {id: "CountryName.GN", defaultMessage: "Guinea"},
  GM: {id: "CountryName.GM", defaultMessage: "Gambia"},
  GW: {id: "CountryName.GW", defaultMessage: "Guinea Bissau"},
  GQ: {id: "CountryName.GQ", defaultMessage: "Equatorial Guinea"},
  GR: {id: "CountryName.GR", defaultMessage: "Greece"},
  GL: {id: "CountryName.GL", defaultMessage: "Greenland"},
  GT: {id: "CountryName.GT", defaultMessage: "Guatemala"},
  GY: {id: "CountryName.GY", defaultMessage: "Guyana"},
  HN: {id: "CountryName.HN", defaultMessage: "Honduras"},
  HR: {id: "CountryName.HR", defaultMessage: "Croatia"},
  HT: {id: "CountryName.HT", defaultMessage: "Haiti"},
  HU: {id: "CountryName.HU", defaultMessage: "Hungary"},
  ID: {id: "CountryName.ID", defaultMessage: "Indonesia"},
  IN: {id: "CountryName.IN", defaultMessage: "India"},
  IE: {id: "CountryName.IE", defaultMessage: "Ireland"},
  IR: {id: "CountryName.IR", defaultMessage: "Iran"},
  IQ: {id: "CountryName.IQ", defaultMessage: "Iraq"},
  IS: {id: "CountryName.IS", defaultMessage: "Iceland"},
  IL: {id: "CountryName.IL", defaultMessage: "Israel"},
  IT: {id: "CountryName.IT", defaultMessage: "Italy"},
  JM: {id: "CountryName.JM", defaultMessage: "Jamaica"},
  JO: {id: "CountryName.JO", defaultMessage: "Jordan"},
  JP: {id: "CountryName.JP", defaultMessage: "Japan"},
  KZ: {id: "CountryName.KZ", defaultMessage: "Kazakhstan"},
  KE: {id: "CountryName.KE", defaultMessage: "Kenya"},
  KG: {id: "CountryName.KG", defaultMessage: "Kyrgyzstan"},
  KH: {id: "CountryName.KH", defaultMessage: "Cambodia"},
  KR: {id: "CountryName.KR", defaultMessage: "South Korea"},
  KW: {id: "CountryName.KW", defaultMessage: "Kuwait"},
  LA: {id: "CountryName.LA", defaultMessage: "Laos"},
  LB: {id: "CountryName.LB", defaultMessage: "Lebanon"},
  LR: {id: "CountryName.LR", defaultMessage: "Liberia"},
  LY: {id: "CountryName.LY", defaultMessage: "Libya"},
  LK: {id: "CountryName.LK", defaultMessage: "Sri Lanka"},
  LS: {id: "CountryName.LS", defaultMessage: "Lesotho"},
  LT: {id: "CountryName.LT", defaultMessage: "Lithuania"},
  LU: {id: "CountryName.LU", defaultMessage: "Luxembourg"},
  LV: {id: "CountryName.LV", defaultMessage: "Latvia"},
  MA: {id: "CountryName.MA", defaultMessage: "Morocco"},
  MD: {id: "CountryName.MD", defaultMessage: "Moldova"},
  MG: {id: "CountryName.MG", defaultMessage: "Madagascar"},
  MX: {id: "CountryName.MX", defaultMessage: "Mexico"},
  MK: {id: "CountryName.MK", defaultMessage: "Macedonia"},
  ML: {id: "CountryName.ML", defaultMessage: "Mali"},
  MM: {id: "CountryName.MM", defaultMessage: "Myanmar"},
  ME: {id: "CountryName.ME", defaultMessage: "Montenegro"},
  MN: {id: "CountryName.MN", defaultMessage: "Mongolia"},
  MZ: {id: "CountryName.MZ", defaultMessage: "Mozambique"},
  MR: {id: "CountryName.MR", defaultMessage: "Mauritania"},
  MW: {id: "CountryName.MW", defaultMessage: "Malawi"},
  MY: {id: "CountryName.MY", defaultMessage: "Malaysia"},
  NA: {id: "CountryName.NA", defaultMessage: "Namibia"},
  NC: {id: "CountryName.NC", defaultMessage: "New Caledonia"},
  NE: {id: "CountryName.NE", defaultMessage: "Niger"},
  NG: {id: "CountryName.NG", defaultMessage: "Nigeria"},
  NI: {id: "CountryName.NI", defaultMessage: "Nicaragua"},
  NL: {id: "CountryName.NL", defaultMessage: "Netherlands"},
  NO: {id: "CountryName.NO", defaultMessage: "Norway"},
  NP: {id: "CountryName.NP", defaultMessage: "Nepal"},
  NZ: {id: "CountryName.NZ", defaultMessage: "New Zealand"},
  OM: {id: "CountryName.OM", defaultMessage: "Oman"},
  PK: {id: "CountryName.PK", defaultMessage: "Pakistan"},
  PA: {id: "CountryName.PA", defaultMessage: "Panama"},
  PE: {id: "CountryName.PE", defaultMessage: "Peru"},
  PH: {id: "CountryName.PH", defaultMessage: "Philippines"},
  PG: {id: "CountryName.PG", defaultMessage: "Papua New Guinea"},
  PL: {id: "CountryName.PL", defaultMessage: "Poland"},
  PR: {id: "CountryName.PR", defaultMessage: "Puerto Rico"},
  KP: {id: "CountryName.KP", defaultMessage: "North Korea"},
  PT: {id: "CountryName.PT", defaultMessage: "Portugal"},
  PY: {id: "CountryName.PY", defaultMessage: "Paraguay"},
  QA: {id: "CountryName.QA", defaultMessage: "Qatar"},
  RO: {id: "CountryName.RO", defaultMessage: "Romania"},
  RU: {id: "CountryName.RU", defaultMessage: "Russia"},
  RW: {id: "CountryName.RW", defaultMessage: "Rwanda"},
  SA: {id: "CountryName.SA", defaultMessage: "Saudi Arabia"},
  SD: {id: "CountryName.SD", defaultMessage: "Sudan"},
  SS: {id: "CountryName.SS", defaultMessage: "South Sudan"},
  SN: {id: "CountryName.SN", defaultMessage: "Senegal"},
  SB: {id: "CountryName.SB", defaultMessage: "Solomon Islands"},
  SL: {id: "CountryName.SL", defaultMessage: "Sierra Leone"},
  SV: {id: "CountryName.SV", defaultMessage: "El Salvador"},
  SO: {id: "CountryName.SO", defaultMessage: "Somalia"},
  RS: {id: "CountryName.RS", defaultMessage: "Serbia"},
  SR: {id: "CountryName.SR", defaultMessage: "Suriname"},
  SK: {id: "CountryName.SK", defaultMessage: "Slovakia"},
  SI: {id: "CountryName.SI", defaultMessage: "Slovenia"},
  SE: {id: "CountryName.SE", defaultMessage: "Sweden"},
  SZ: {id: "CountryName.SZ", defaultMessage: "Swaziland"},
  SY: {id: "CountryName.SY", defaultMessage: "Syria"},
  TD: {id: "CountryName.TD", defaultMessage: "Chad"},
  TG: {id: "CountryName.TG", defaultMessage: "Togo"},
  TH: {id: "CountryName.TH", defaultMessage: "Thailand"},
  TJ: {id: "CountryName.TJ", defaultMessage: "Tajikistan"},
  TM: {id: "CountryName.TM", defaultMessage: "Turkmenistan"},
  TL: {id: "CountryName.TL", defaultMessage: "East Timor"},
  TT: {id: "CountryName.TT", defaultMessage: "Trinidad and Tobago"},
  TN: {id: "CountryName.TN", defaultMessage: "Tunisia"},
  TR: {id: "CountryName.TR", defaultMessage: "Turkey"},
  TW: {id: "CountryName.TW", defaultMessage: "Taiwan"},
  TZ: {id: "CountryName.TZ", defaultMessage: "Tanzania"},
  UG: {id: "CountryName.UG", defaultMessage: "Uganda"},
  UA: {id: "CountryName.UA", defaultMessage: "Ukraine"},
  UY: {id: "CountryName.UY", defaultMessage: "Uruguay"},
  US: {id: "CountryName.US", defaultMessage: "United States"},
  UZ: {id: "CountryName.UZ", defaultMessage: "Uzbekistan"},
  VE: {id: "CountryName.VE", defaultMessage: "Venezuela"},
  VN: {id: "CountryName.VN", defaultMessage: "Vietnam"},
  VU: {id: "CountryName.VU", defaultMessage: "Vanuatu"},
  PS: {id: "CountryName.PS", defaultMessage: "West Bank"},
  YE: {id: "CountryName.YE", defaultMessage: "Yemen"},
  ZA: {id: "CountryName.ZA", defaultMessage: "South Africa"},
  ZM: {id: "CountryName.ZM", defaultMessage: "Zambia"},
  ZW: {id: "CountryName.ZW", defaultMessage: "Zimbabwe"},
})
